<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/logo.png')"
              max-height="50px"
              max-width="50px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">Vlera Art</h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Welcome to Vlera Art! 👋🏻
          </p>
          <p class="mb-2">Please sign-in to your account and manage everything!</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="john@example.com"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="
                isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline
              "
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>
            <p class="mb-2" style="color: red">
              {{ error }}
            </p>
            <div class="d-flex align-center justify-space-between flex-wrap">
              <!-- forgot link -->
              <!--              <a href="javascript:void(0)" class="mt-1"> Forgot Password? </a>
-->
            </div>

            <v-btn v-on:click="login" block color="primary" class="mt-6"> Login </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="
        require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)
      "
    />

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { getUserByEmail, login } from "@/ApiManager";
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from "@mdi/js";
import { ref } from "@vue/composition-api";
import { getAuth } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
export default {
  data() {
    return {
      rememberme: true,
      error: "",
    };
  },
  methods: {
    login: async function () {
      this.error = "";
      this.$store.state.loading = true;
      var auth = getAuth();
      console.log("a");

      var data = await login(this.email, this.password);
      if (data == null) {
        this.error = "wrong Email/Password";
        this.$store.state.loading == false;
        return;
      }

      if (
        ["manager", "designer", "production", "sales_agent", "chief_designer"].includes(
          data.role
        ) &&
        data.activated == 1
      ) {
        localStorage.setItem("user", JSON.stringify(data));
        if (data.role == "manager") {
          this.$router.push({ name: "dashboard" });
        }
        if (data.role == "designer") {
          this.$router.push({ name: "orders_designer" });
        }
        if (data.role == "production") {
          this.$router.push({ name: "orders_production" });
        }
        if (data.role == "sales_agent") {
          this.$router.push({ name: "orders_sales" });
        }
        if (data.role == "chief_designer") {
          this.$router.push({ name: "chief_designer_orders" });
        }
      } else {
        this.error = "Account not Authorized";
        this.$store.state.loading == false;
      }
    },
  },
  setup() {
    const isPasswordVisible = ref(false);
    const email = ref("");
    const password = ref("");

    return {
      isPasswordVisible,
      email,
      password,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    };
  },
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
</style>
